import React, { useState } from "react"
// import { graphql } from "gatsby"
import { PageLayout, PageTitle, BlogLink as NewsLink, ProjectLink } from "components"
import { SEO, Utils } from "components/../utils"
import { Container, Form, FormControl } from "react-bootstrap"

import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import Layout from "components/layout.js"
// import SEO from "components/seo.js"
import GridIcons from "components/content/gridicons"
import Helmet from "react-helmet"

// import  from "components/"
import { Menu, MenuCTA } from "components/content/menu"

const contentTypeKey = "news"
const contentTypeName = "News"

const News = ({ data }) => {
  const [state, setState] = useState({
    filteredData: [],
    query: "",
  })

  const allFeaturedImages = data.allFile.edges || []
  const allPosts = data.allMarkdownRemark.edges || []
  const regex = /\/[news].*\/|$/
  const featuredImageMap = Utils.getImageMap(allFeaturedImages, regex)

  const handleChange = e => {
    const query = e.target.value

    const filteredData = allPosts.filter(post => {
      // query will run on the following fields
      const { description, title, tags, author } = post.node.frontmatter
      // standardize query
      const stdQuery = query.toLowerCase()
      
      let excerpt = post.node.excerpt || '';
      return (
        excerpt.toLowerCase().includes(stdQuery) ||
        (description && description.toLowerCase().includes(stdQuery)) ||
        title.toLowerCase().includes(stdQuery) ||
        author.toLowerCase().includes(stdQuery) ||
        (tags &&
          tags
            .join("")
            .toLowerCase()
            .includes(stdQuery))
      )
    })

    setState({
      query,
      filteredData,
    })
  }

  const { filteredData, query } = state
  const filteredPosts = query !== "" ? filteredData : allPosts

  return (
    <Layout>
      <SEO title={contentTypeName} />
    
      <PageTitle title={contentTypeName} />
      <div  className="container">
      <Container className="px-5 mb-5 text-center">
        <Form className="aurebesh news-filter">
          <FormControl
            className="bg-none search"
            type="text"
            placeholder="Search"
            onChange={handleChange}
          />
        </Form>
      </Container>
      <Container
        fluid
        className="p-3 w-auto text-left d-flex2 flex-wrap justify-content-left"
      >
        {filteredPosts.map(({ node }) => (
          <div key={node.id} className="p-3">
            <NewsLink
              // to={node.frontmatter.slug}
              // featuredImage={featuredImageMap[node.frontmatter.slug]}
              to={node.frontmatter.path}
              featuredImage={featuredImageMap[node.frontmatter.path]}
              title={node.frontmatter.title}
              subtitle={node.frontmatter.date}
              excerpt={node.excerpt}
            />
          </div>
        ))}
      </Container>
      </div>
       </Layout>
  )
}

export default News;
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { 
        fileAbsolutePath: {regex: "/content/news/" }   ,  frontmatter: { published: { eq: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            author
            date(formatString: "DD MMMM, YYYY")
            slug
            path
            published
          }
          excerpt
        }
      }
    }
    allFile(
      filter: {
        extension: { eq: "jpg" }
        relativePath: { regex: "/feature/" }
        relativeDirectory: { regex: "/content/news/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
          relativePath
        }
      }
    }
  }
`

          // fields {
          //   slug
          // }